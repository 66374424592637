import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Fujairahcenter from '../../images/fujairah-cultural-center2.webp';
import Kalbatv from '../../images/kalba-tv.webp';
import Khalifauni from '../../images/khalifa-university.webp';
import Thumbay from '../../images/thumbay-group.webp';
import Americanschool from '../../images/american-school.webp';
import './projects.css';
function projects() {
    return (

        <>
            <div className="boxed_wrapper" id="project">
                <Helmet>
                    <title>Our Recently Completed Acoustic Projects Vol.5</title>
                    <meta name="description" content="We are extremely fortunate to be part of these iconic projects (vol.5)." />
                    <meta property="og:title" content="Our Recently Completed Acoustic Projects" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/projects-v5" />
                    <link rel="alternate" href="https://www.moovgroup.com/projects-v5" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section className="breadcrumb-section projects-bg">
                        <div className="container">
                            <span className="page-tag">Recently</span>
                            <h1 className="page-title">Completed Projects</h1>
                        </div>
                    </section>

                    <section className="portfolio-section section-gap" id="portfolio">
                        <div className="container">
                            <div className="portfolio-items">
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Fujairahcenter} alt="Fujairah Cultural Center" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="culturalcenter-img"></div>
                                                <div className="img-bot">
                                                    <div className="culturalcenter-img2"></div>
                                                    <div className="culturalcenter-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">28.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/fujairah-auditorium-acoustics">Fujairah Cultural Center Auditorium</Link></h5>
                                                <p>Location: Fujairah, UAE</p>
                                                <p>Client: Ministry of Public Works</p>
                                                <p>Consultant  Space Consultant</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>
                                                    <ul>
                                                        <li>Acoustic Design, Supply and Installation.</li>
                                                        <li>Complete acoustic treatment on wall and ceiling.</li>
                                                    </ul>
                                                </p>
                                                <Link to="/fujairah-auditorium-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Kalbatv} alt="Kalba TV" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="kalbatv-img"></div>
                                                <div className="img-bot">
                                                    <div className="kalbatv-img2"></div>
                                                    <div className="kalbatv-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">29.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/kalba-tv-acoustics">Kalba TV</Link></h5>
                                                <p>Location: Sharjah, UAE</p>
                                                <p>Client: Govt. of Sharjah, Directorate of Public Works, UAE</p>
                                                <p>Consultant  C.A.B. Consultant</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>This local documentary channel is dedicated to highlighting the charming beauty of the Eastern Coast and the Eastern Region, which is distinguished by its beautiful natural scenery, rich history and heritage, as well as in its environmental diversity.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>Supply, Erection, Testing and Commissioning of complete acoustics for studios, control room and supporting rooms of carpet area approv 500 sqmt.</p>
                                                <Link to="/kalba-tv-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Khalifauni} alt="Khalifa University" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="khalifauniversity-img"></div>
                                                <div className="img-bot">
                                                    <div className="khalifauniversity-img2"></div>
                                                    <div className="khalifauniversity-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">30.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/khalifa-university-acoustics">Khalifa University</Link>
                                                </h5>
                                                <p>Location: Abu Dhabi, UAE</p>
                                                <p>Client: Khalifa University</p>
                                                <p>Consultant  Lead Consultant</p>
                                                <p>Main Contractor  General Contracting LLC</p>
                                                <p>Contractor  Fibrex Construction Group</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>The newly-established Khalifa University of Science and Technology combines The Masdar Institute of Science and Technology, Khalifa University of Science, Technology and Research and The Petroleum Institute into one world-className, research-intensive institution, seamlessly integrating research and education to produce world leaders and critical thinkers in applied science and engineering.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>Wooden Acoustic Panel with Five different colors for each block Wood wool panel for all Meeting rooms Fabric Cladding for Multiuse Halls Wall Panel Area – 10000 Sqmt</p>
                                                <Link to="/khalifa-university-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Thumbay} alt="Thumbay Group" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="thumbayinstitute-img"></div>
                                                <div className="img-bot">
                                                    <div className="thumbayinstitute-img2"></div>
                                                    <div className="thumbayinstitute-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">31.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/thumbay-group-acoustics">Thumbay - Gulf Medical University</Link></h5>
                                                <p>Location:  Ajman, UAE</p>
                                                <p>Client  Thumbay Group</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>The Thumbay Institute for precision medicine and translational research, Thumbay institute for population health and Thumbay Institute of health workforce development are the hubs for research, post-graduate studies, and continuous professional development.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>Design, Supply and acoustic treatment 4 lecture halls with the seating of 180-200 each.</p>
                                                <Link to="/thumbay-group-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Americanschool} alt="American school" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="americanschool-img"></div>
                                                <div className="img-bot">
                                                    <div className="americanschool-img2"></div>
                                                    <div className="americanschool-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">32.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/american-school-acoustics">American School of Creative Science</Link></h5>
                                                <p>Location: Sharjah, UAE</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>36 className rooms acoustics fit outworks(wall & ceiling)</p>
                                                <p>Library-Acoustics wall and ceiling works</p>
                                                <p>Laboratory-Acoustic fit out works</p>
                                                <p>Play area-Acoustic works(Wall & Ceiling)</p>
                                                <p>Indoor play area-Acoustic fit out works</p>
                                                <p>Corridors-Sound absorption panels</p>
                                                <Link to="/american-school-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-items">
                                    <div className="portfolio-item portfolio-style-four">
                                        <span className="line-footer"></span>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container1">
                                                    <Link to="/projects-v4" className="portfolio-previous">&laquo; Previous</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container2">
                                                    <Link to="/projects-v6" className="portfolio-next">Next &raquo;</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default projects;