import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Homecinemapvt from '../../images/private-homecinema.webp';
import orbi from '../../images/orbi-theatre.webp';
import Auditoriumasi from '../../images/auditorium-acoustics.webp';
import Emal from '../../images/emal.webp';
import Archiasi from '../../images/architectural-acoustics.webp';
import './projects.css';
function projects() {
    return (

        <>
            <div className="boxed_wrapper" id="project">
                <Helmet>
                    <title>Our Recently Completed Acoustic Projects Vol.4</title>
                    <meta name="description" content="We are extremely fortunate to be part of these iconic projects (vol.4)." />
                    <meta property="og:title" content="Our Recently Completed Acoustic Projects" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/projects-v4" />
                    <link rel="alternate" href="https://www.moovgroup.com/projects-v4" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section className="breadcrumb-section projects-bg">
                        <div className="container">
                            <span className="page-tag">Recently</span>
                            <h1 className="page-title">Completed Projects</h1>
                        </div>
                    </section>

                    <section className="portfolio-section section-gap" id="portfolio">
                        <div className="container">
                            <div className="portfolio-items">
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Homecinemapvt} alt="Private Home Cinema" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="privatehomecinema-img"></div>
                                                <div className="img-bot">
                                                    <div className="privatehomecinema-img2"></div>
                                                    <div className="privatehomecinema-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">23.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/private-home-cinema-acoustics">Private Home Cinema</Link></h5>
                                                <p>Location: Dubai</p>
                                                <p>Client: HK Enterprises</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>Bringing theatre home with excellent sound quality effect acoustically with the best AV equipments</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>
                                                    <ul>
                                                        <li>Sound Proofing</li>
                                                        <li>Wallmate - Noise absorption paneling</li>
                                                        <li>AV systems</li>
                                                        <li>Flooring (Carpet with Underlay)</li>
                                                        <li>Lighting</li>
                                                    </ul>
                                                </p>
                                                <Link to="/private-home-cinema-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={orbi} alt="Orbi Theatre" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="orbitheatre-img"></div>
                                                <div className="img-bot">
                                                    <div className="orbitheatre-img2"></div>
                                                    <div className="orbitheatre-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">24.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/orbi-theatre-acoustics">Orbi theatre</Link></h5>
                                                <p>Location:  Dubai, UAE</p>
                                                <p>Client: Orbi theatre</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>Orbi Theater consists of twelve different nature zones designed to create experiences that would be impossible in the real world where visitors can soar through Earths skies, follow in the footsteps of polar explorers and experience the thunder of being placed in the centre of an elephant migration. The centrepiece of the attraction is the Earth Theatre, a 35-metre-wide screen with a powerful 3D sound system where the natural world comes to life through state of the art technologies including fog, vibratory sensations and fragrances.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>
                                                    <ul>
                                                        <li>Acoustic Paneling</li>
                                                        <li>50 MM Wallmate with leed eligible Echo Eliminator</li>
                                                        <li>Installation of Baffles</li>
                                                    </ul>
                                                </p>
                                                <Link to="/orbi-theatre-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Auditoriumasi} alt="Auditorium Acoustics" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="abudhabiport-img"></div>
                                                <div className="img-bot">
                                                    <div className="abudhabiport-img2"></div>
                                                    <div className="abudhabiport-img3"></div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">25.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/auditorium-port-authority-acoustics">Auditorium - AbuDhabi Port Authority</Link></h5>
                                                <p>Location: Abu Dhabi</p>
                                                <p>Client: Abu Dhabi ports</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>Abu Dhabi port operates in highly competitive, capital-intensive and globally connected industries. The ports provide vital infrastructure for the maritime industry, promoting the flow of those imports and exports, whilst supporting local communities and sustainable businesses, as well as the broader economy. Abu Dhabi Ports is also developing Khalifa Industrial Zone Abu Dhabi (KIZAD), Khalifa Ports trade and logistics hub. Together, Khalifa Port and KIZAD are fully integrated providing excellent supply chain connections and efficiencies by combining technology, innovative systems and excellent infrastructure into a unique offering.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>Acoustic Design, Supply, Installation, Testing and Commissioning for the complete auditorium.</p>
                                                <Link to="/auditorium-port-authority-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Emal} alt="EMAL" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="emal-img"></div>
                                                <div className="img-bot">
                                                    <div className="emal-img2"></div>
                                                    <div className="emal-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">26.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/emal-acoustics">Emirates Aluminum Company (EMAL) Auditorium</Link></h5>
                                                <p>Location: Abu Dhabi, UAE</p>
                                                <p>Client: Emirates Aluminium Company Limited</p>
                                                <p>Consultant  ECG Engineering consultants Group</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>Emirates Aluminum Company (EMAL) has Aluminum Smelter and associated facilities at Al Taweelah, 300 km North East of Abu Dhabi. EMAL planned to develop and construct contemporary administration offices and support facilities to efficiently serve the smelter.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>Supply and installation of wooden panels on wall and ceiling,GRG Ceiling and parquet flooring.</p>
                                                <Link to="/emal-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Archiasi} alt="Architectural Acoustics" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="africahall-img"></div>
                                                <div className="img-bot">
                                                    <div className="africahall-img2"></div>
                                                    <div className="africahall-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">27.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/africa-hall-acoustics">Africa Hall</Link></h5>
                                                <p>Location: Sharjah, UAE</p>
                                                <p>Consultant & Architect  Architectural Academy Office</p>
                                                <p>Contractor  Fast Engineering Company</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>
                                                    <ul>
                                                        <li>Treatment of the reverberation by installing acoustic fabric wall panels from Wallmate,USA.</li>
                                                        <li>Supply and Installation of Phonstop acoustic ceilings behind the GRC Mashrabiya to control the RT inside the auditorium.</li>
                                                    </ul>
                                                </p>
                                                <Link to="/africa-hall-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="portfolio-items">
                                    <div className="portfolio-item portfolio-style-four">
                                        <span className="line-footer"></span>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container1">
                                                    <Link to="/projects-v3" className="portfolio-previous">&laquo; Previous</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container2">
                                                    <Link to="/projects-v5" className="portfolio-next">Next &raquo;</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default projects;