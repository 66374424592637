import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Ferrariworld from '../../images/ferrari-world-abu-dhabi-moov-group.webp';
import Kapsarc from '../../images/kapsarc-moov-group.webp';
import Prespalace from '../../images/presidential-palace-abu-dhabi-moov-group.webp';
import Skynews from '../../images/skynews.webp';
import Eibfs from '../../images/eibfs.webp';
import './projects.css';
function projects() {
    return (

        <>
            <div className="boxed_wrapper" id="project">
                <Helmet>
                    <title>Our Recently Completed Acoustic Projects vol.1</title>
                    <meta name="description" content="We are extremely fortunate to be part of these iconic projects (vol.1)" />
                    <meta property="og:title" content="Our Recently Completed Acoustic Projects" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/projects-v1" />
                    <link rel="alternate" href="https://www.moovgroup.com/projects-v1" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section className="breadcrumb-section projects-bg">
                        <div className="container">
                            <span className="page-tag">Recently</span>
                            <h1 className="page-title">Completed Projects</h1>
                        </div>
                    </section>

                    <section className="portfolio-section section-gap" id="portfolio">
                        <div className="container">
                            <div className="portfolio-items">
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Ferrariworld} alt="Ferrari World" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="ferrari-img"></div>
                                                <div className="img-bot">
                                                    <div className="ferrari-img2"></div>
                                                    <div className="ferrari-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">08.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/ferrari-world-acoustics">Ferrari World</Link></h5>
                                                <p>Location: Abu Dhabi</p>
                                                <p>Client: Aldar</p>
                                                <p>Architect: Benoy,UK</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>The 86,000 m2 enclosed area of Ferrari World Abu Dhabi can fit 7 football fields in 'head to toe’. The total roof area of 200,000 m2 uses enough aluminum to cover 16,750 Ferraris, or if you laid the roof flat you could place 20,100 Ferrari's side by side and end to end. If Ferrari World Abu Dhabi was turned upright, it would be the tallest man made structure in the world at over 300 floors!</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>We are extremely fortunate to be part of this iconic project where our scope was to treat the walls with acoustic core lined with fabric. We installed fabric wrapped acoustic wall panel system on the wall to increase the sound absorption there by to reduce reverberation.</p>
                                                <Link to="/ferrari-world-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Kapsarc} alt="Kapsarc" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="kapsarc-img"></div>
                                                <div className="img-bot">
                                                    <div className="kapsarc-img2"></div>
                                                    <div className="kapsarc-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">09.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/kapsarc-acoustics">KAPSARC</Link></h5>
                                                <p>Location: Riyadh, Saudi Arabia</p>
                                                <p>Client: Saudi Aramco</p>
                                                <p>Architect: Zaha Hadid</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>An independent non-profit organization dedicated to researching economics and technology across all platforms of energy. Designed by Zaha Hadid Architects, the modular structure is a living, organic scheme where expansions to the building are allowed to grow and multiply like cells – without compromising the visual integrity of the project. The modular structure is a living, organic scheme where expansions grow and multiply like cells Composed of a network of three-dimensional six-sided cells, the project is based on the concept of connectivity. The center rises above the desert landscape, emerging as a cluster of crystalline forms which evolve in response to environmental conditions. The exterior’s shell-like façades conceal the porous interior, where sheltered courtyards bring natural daylight into the center of the scheme.</p>
                                                <p>Construction Area: 66,000m2; Site Area: 530,000 m2; Footprint Area: 28,500 m2</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>We successfully executed a project of this size and complexity where our scope was supply & installation of acoustic wall panels, Echo Panels & Acoustic Treatment for the walls & movable partitions. It is a privilege to be a part of an iconic project.</p>
                                                <Link to="/kapsarc-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Prespalace} alt="Presidential Palace" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="presidential-palace-img"></div>
                                                <div className="img-bot">
                                                    <div className="presidential-palace-img2"></div>
                                                    <div className="presidential-palace-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">10.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/presidential-palace-acoustics">Presidential Palace</Link></h5>
                                                <p>Location: Abu Dhabi, UAE</p>
                                                <p>Client: UAE Federal Government</p>
                                                <p>Architect: Wilson Associates</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>The location of the new Presidential Palace is in Ras Al Akhdar peninsula adjacent to the Emirates Palace Hotel, Abu Dhabi. The development will encompass approximately 150 hectares, and will include the Palace and the required ancillary buildings. The Palace will exceed 160000m2 of built area and will contain several VIP wings, a Ministers wing, and several Majlis and Dining Halls. The Common Areas of the Palace will utilize an additional 5,614 square meters and will include a Main Entrance Hall, Main Hall, Fountain Courtyard, Media Hall, and a Press Center. The ancillary buildings, which incorporate over 23000m2, will include a Public Majlis, Mosque, Staff & Military Accommodations, a Services Compound, and various Gatehouses and Watchtowers. The project also includes 2000m of 6m seawall, 4 million m3 of dredging and 2500m of revetment.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>Supply & Installation of acoustical Wall panels. It was a great opportunity to be part of this iconic project.</p>
                                                <Link to="/presidential-palace-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Skynews} alt="Skynews" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="skynews-img"></div>
                                                <div className="img-bot">
                                                    <div className="skynews-img2"></div>
                                                    <div className="skynews-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">11.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/skynews-acoustics">Sky News</Link></h5>
                                                <p>Location: Abu Dhabi</p>
                                                <p>Client: Abu Dhabi Media</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>News Arabia is an Arabic news and current affairs channel broadcast mainly in the Middle East and North Africa. It is a joint venture between UK-based BSkyB and Abu Dhabi Media Investment Corporation (ADMIC), The channel is based in Abu Dhabi's twofour54 media zone and has a network of bureaux across MENA, along with offices in London and Washington, DC. It also shares access to Sky News' wider international bureaux network.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>Our Scope We were entrusted with lining the walls with acoustic fabric wall panels in the studio and the edit suites. Our design and treatment helped to optimize the Reverberation Times in the Studio.</p>
                                                <Link to="/skynews-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Eibfs} alt="Eibfs" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="eibf-img"></div>
                                                <div className="img-bot">
                                                    <div className="eibf-img2"></div>
                                                    <div className="eibf-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">12.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/eibfs-acoustics">Emirates Institute of Banking & Financial Studies</Link></h5>
                                                <p>Location: Dubai, UAE</p>
                                                <p>Client: Dubai Government</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p>Emirates Institute For Banking and Financial Studies (EIBFS) has made quantum leaps by delivering world className education, training and allied services in the area of Banking and Finance at its three campuses strategically located at Sharjah, Abu Dhabi and Dubai. EIBFS has been globally integrated with various leading Institutes and universities of the world to bring and offer the best programs and courses to UAE in the domain area of banking and finance.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>Acoustical Interior fit out which include In-house acoustic consultancy & Design, Acoustical wall treatment & ceiling.</p>
                                                <Link to="/eibfs-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-items">
                                    <div className="portfolio-item portfolio-style-four">
                                        <span className="line-footer"></span>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container1">
                                                    <Link to="/projects" className="portfolio-previous">&laquo; Previous</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container2">
                                                    <Link to="/projects-v2" className="portfolio-next">Next &raquo;</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default projects;