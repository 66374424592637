import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Primaryschool from '../../images/primary-schools.webp';
import Novocinemas from '../../images/novo-cinemas.webp';
import Novo from '../../images/novo-img.webp';
import Azamtv from '../../images/azam-tv.webp';
import Themepark from '../../images/theme-park-acoustics.webp';
import './projects.css';
function projects() {
    return (

        <>
            <div className="boxed_wrapper" id="project">
                <Helmet>
                    <title>Our Recently Completed Acoustic Projects Vol.3</title>
                    <meta name="description" content="We are extremely fortunate to be part of these iconic projects (vol.3)." />
                    <meta property="og:title" content="Our Recently Completed Acoustic Projects" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/projects-v3" />
                    <link rel="alternate" href="https://www.moovgroup.com/projects-v3" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section class="breadcrumb-section projects-bg">
                        <div class="container">
                            <span class="page-tag">Recently</span>
                            <h1 class="page-title">Completed Projects</h1>
                        </div>
                    </section>

                    <section class="portfolio-section section-gap" id="portfolio">
                        <div class="container">
                            <div class="portfolio-items">
                                <div class="portfolio-item portfolio-style-four mb-5">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 mt-4">
                                            <div class="portfolio-thumb">
                                                <img src={Primaryschool} alt="Primary school" />
                                            </div>
                                            <div class="gallery images">
                                                <div class="primaryschools-img"></div>
                                                <div class="img-bot">
                                                    <div class="primaryschools-img2"></div>
                                                    <div class="primaryschools-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mt-2">
                                            <div class="portfolio-desc">
                                                <span class="count">18.</span>
                                                <span class="line"></span>
                                                <h5 class="title"><Link to="/primary-schools-acoustics">Primary Schools</Link></h5>
                                                <p>Location: UAE</p>
                                                <p>Client:Ministry of Public Works</p>
                                                <p>Consultant: CAB</p>
                                                <span class="completed-date mb-0 mt-2">About</span>
                                                <p>Primary School auditorium</p>
                                                <span class="completed-date mb-0 mt-2">Scope</span>
                                                <p>
                                                    <ul>
                                                        <li>Wall panel</li>
                                                        <li>Wood floor</li>
                                                        <li>Grooved wood panel</li>
                                                        <li>Acoustic ceiling</li>
                                                    </ul>
                                                </p>
                                                <Link to="/primary-schools-acoustics" class="portfolio-link">Project Details <span class="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="line-footer1"></span>
                                <div class="portfolio-item portfolio-style-four mb-5">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div class="portfolio-thumb">
                                                <img src={Novocinemas} alt="Novo Cinemas" />
                                            </div>
                                            <div class="gallery images">
                                                <div class="novocinemas-img"></div>
                                                <div class="img-bot">
                                                    <div class="novocinemas-img2"></div>
                                                    <div class="novocinemas-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mt-2">
                                            <div class="portfolio-desc">
                                                <span class="count">19.</span>
                                                <span class="line"></span>
                                                <h5 class="title"><Link to="/novo-cinemas-acoustics">Novo Cinemas</Link></h5>
                                                <p>Location: bahrain & Qatar</p>
                                                <p>Client: Novo Cinemas</p>
                                                <span class="completed-date mb-0 mt-2">About</span>
                                                <p>Novo Cinemas is a Movie Theatre chain owned by Gulf Films LLC operating in the UAE, Qatar and Bahrain. It is headquartered in Dubai and considered as the largest cinema chain in the Middle East.</p>
                                                <p>Construction Area: 66,000m2; Site Area: 530,000 m2; Footprint Area: 28,500 m2</p>
                                                <span class="completed-date mb-0 mt-2">Scope</span>
                                                <p>
                                                    <ul>
                                                        <li>Supply of 3D Acoustical Panels for more than 40 screens in Qatar and Bahrain.</li>
                                                        <li>Novo IMG Theme Park - Complete Acoustical solution for 12 screens.</li>
                                                    </ul>
                                                </p>
                                                <Link to="/novo-cinemas-acoustics" class="portfolio-link">Project Details <span class="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="line-footer1"></span>
                                <div class="portfolio-item portfolio-style-four mb-5">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 mt-4">
                                            <div class="portfolio-thumb">
                                                <img src={Novo} alt="Novo" />
                                            </div>
                                            <div class="gallery images">
                                                <div class="novocinemasimg-img"></div>
                                                <div class="img-bot">
                                                    <div class="novocinemasimg-img2"></div>
                                                    <div class="novocinemasimg-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mt-2">
                                            <div class="portfolio-desc">
                                                <span class="count">20.</span>
                                                <span class="line"></span>
                                                <h5 class="title"><Link to="/novo-park-acoustics">Novo Cinemas - IMG World Theme Park</Link></h5>
                                                <p>Location: Dubai, UAE</p>
                                                <p>Client: Novo Cinemas</p>
                                                <span class="completed-date mb-0 mt-2">About</span>
                                                <p>Novo Cinemas is a Movie Theatre chain owned by Gulf Films LLC operating in the UAE, Qatar and Bahrain. It is headquartered in Dubai and considered as the largest cinema chain in the Middle East.</p>
                                                <span class="completed-date mb-0 mt-2">Scope</span>
                                                <p>Complete Acoustic Wall Paneling and Lighting.</p>
                                                <Link to="/novo-park-acoustics" class="portfolio-link">Project Details <span class="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="line-footer1"></span>
                                <div class="portfolio-item portfolio-style-four mb-5">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div class="portfolio-thumb">
                                                <img src={Azamtv} alt="Azam TV" />
                                            </div>
                                            <div class="gallery images">
                                                <div class="azamtv-img"></div>
                                                <div class="img-bot">
                                                    <div class="azamtv-img2"></div>
                                                    <div class="azamtv-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mt-2">
                                            <div class="portfolio-desc">
                                                <span class="count">21.</span>
                                                <span class="line"></span>
                                                <h5 class="title"><Link to="/azam-tv-acoustics">AZAM TV</Link></h5>
                                                <p>Location: Tanzania</p>
                                                <p>Client: ANVICON</p>

                                                <span class="completed-date mb-0 mt-2">About</span>
                                                <p>TV studio</p>
                                                <span class="completed-date mb-0 mt-2">Scope</span>
                                                <p>
                                                    <ul>
                                                        <li>Acoustic wall panel</li>
                                                        <li>Soundproofing</li>
                                                        <il>Raised access floor</il>
                                                    </ul>
                                                </p>
                                                <Link to="/azam-tv-acoustics" class="portfolio-link">Project Details <span class="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="line-footer1"></span>
                                <div class="portfolio-item portfolio-style-four mb-5">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 mt-4">
                                            <div class="portfolio-thumb">
                                                <img src={Themepark} alt="Theme Park" />
                                            </div>
                                            <div class="gallery images">
                                                <div class="dubaiparks-img"></div>
                                                <div class="img-bot">
                                                    <div class="dubaiparks-img2"></div>
                                                    <div class="dubaiparks-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mt-2">
                                            <div class="portfolio-desc">
                                                <span class="count">22.</span>
                                                <span class="line"></span>
                                                <h5 class="title"><Link to="/dubai-park-acoustics">Dubai Parks</Link></h5>
                                                <p>Location: Dubai, UAE</p>
                                                <p>Client: Dubai Parks</p>
                                                <span class="completed-date mb-0 mt-2">About</span>
                                                <p>Dubai Parks and Resorts is the Middle Easts largest integrated leisure and theme park destination located on Sheikh Zayed road in Dubai, UAE. Spread over 25 million square feet, it consists of three theme parks, Bollywood Parks and LEGOLAND. It also encompasses Riverland Dubai and the Polynesian-themed family resort, Lapita Hotel.</p>
                                                <span class="completed-date mb-0 mt-2">Scope</span>
                                                <p>
                                                    <ul>
                                                        <li>MotionGate - Acoustic Stage Floor, Wall and Ceiling Acoustics in 3 Theatres</li>
                                                        <li>Rajmahal – Supplied Insulation for Wall Panels</li>
                                                        <li>LEGOLAND– Acoustic Ceiling Works</li>
                                                    </ul>
                                                </p>
                                                <Link to="/dubai-park-acoustics" class="portfolio-link">Project Details <span class="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="portfolio-items">
                                    <div class="portfolio-item portfolio-style-four">
                                        <span class="line-footer"></span>
                                        <div class="row align-items-center">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="portfolio-container1">
                                                    <Link to="/projects-v2" class="portfolio-previous">&laquo; Previous</Link>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="portfolio-container2">
                                                    <Link to="/projects-v4" class="portfolio-next">Next &raquo;</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default projects;